.formInput {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.formContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 40rem;
  width: '100%';
}
