.icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 120px;

  .icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    cursor: pointer;
  }

  .thumb {
    path {
      stroke: var(--color-inactive);
      fill: var(--color-inactive);
    }
  }

  .activeIcon {
    path {
      stroke: var(--color-active);
      fill: var(--color-active);
    }
  }

  .copy {
    color: var(--color-gray);
  }
}
