.loaderContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.loaderBox {
  display: flex;
  margin: auto;
  background: white;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  padding: 10px;
  gap: 5px;
}
