@import 'src/app/common/styles/breakpoints';

.serviceItem {
  padding: 16px 24px;
  border-bottom: 1px solid var(--color-gray-border);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;

    .content {
      .title {
        color: var(--color-text2);
        font-family: 'Segoe UI', sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.2;
        padding-bottom: 4px;
      }

      .text {
        overflow: hidden;
        color: var(--grey, #98a4b6);
        text-overflow: ellipsis;
        font-family: 'Segoe UI', sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.43;
        display: -webkit-box;
        word-wrap: break-word;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -webkit-box-orient: vertical;
      }
    }
  }

  .standards {
    border-radius: 16px;
    background-color: var(--color-gray2);
    margin-top: 16px;
    padding: 24px;

    .additionalContentContainer {
      margin: 16px 0;

      .additionalContent {
        color: var(--color-text2);
        font-family: 'Segoe UI', sans-serif;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;

        .list {
          .listItem {
            padding: 8px 0;
          }
        }

        .paragraph {
          &:not(:first-of-type) {
            padding-top: 8px;
          }
        }
      }
    }

    .dateBlock {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .date {
        color: var(--color-gray);
        font-family: 'Segoe UI', sans-serif;
        font-size: 16px;
        line-height: 1.5;
        font-weight: 400;
      }
    }
  }
}

.borderBottomUnset {
  border-bottom: unset;
}
