@import '../../../styles/breakpoints';

.chat {
  background-color: var(--color-background);
  padding: 0 24px 28px 24px;
  overflow-y: hidden;

  .container {
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media only screen and (max-width: $screen-md) {
      width: 96%;
    }

    .messages {
      overflow-y: auto;
      display: flex;
      flex-direction: column-reverse;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .loader {
        width: 100%;
        margin-top: 10px;
      }
    }

    .messageLoader {
      margin-bottom: 10px;
      width: 100px;
    }
  }
}
.chatHeightFull {
  height: calc(100vh - 216px);
}

.chatHeightCompact {
  height: calc(100vh - 100px);
}
