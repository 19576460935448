$white: #fff;
$mineShaft: #222;
$mainTextColor: #080808;
$grayTextColor: #98a4b6;
$blue: #4962a8;
$lightBlue: #f0f6ff;
$darkGray: #5a6472;
$gray: #98a4b6;
$gray2: #f7f7f8;
$gray3: #e4e4e4;
$lightGray: #ececec;
$lightGray2: #b6b6b6;
$lightGray3: #f0f0f0;
$black: #000;
$red: #ff0000;

:root {
  --color-text: #{$mainTextColor};
  --color-text-minor: #{$white};
  --color-background: #{$white};
  --color-background-minor: #{$mineShaft};
  --color-header-background: #{$darkGray};
  --color-border: #{$lightGray};
  --color-blue: #{$blue};
  --color-blue-light: #{$lightBlue};
  --color-text2: #{$black};
  --color-gray: #{$gray};
  --color-gray2: #{$gray2};
  --color-gray-border: #{$gray3};
  --color-light-gray: #{$lightGray2};
  --color-light-gray3: #{$lightGray3};
  --color-gray-text: #{$grayTextColor};
  --color-inactive: #{$gray};
  --color-active: #{$blue};
  --color-error: #{$red};
}
