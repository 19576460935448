.dotsLoader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-blue);
    flex-shrink: 0;
    animation: dots 0.9s ease-in-out infinite;

    &:nth-child(2) {
      animation-delay: 0.3s;
    }

    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
}

@keyframes dots {
  50% {
    transform: scale(0.6);
  }
  100% {
    transform: translateY(0);
  }
}

.centered {
  height: calc(100% - 65px);
  flex-basis: 100%;
}
