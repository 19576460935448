@import '../../../styles/breakpoints';

.whiteSidebar {
  background-color: var(--color-background);
  box-shadow: 1px 0 21.7px 0 rgba(0, 0, 0, 0.08);
  border-right: 1px solid var(--color-border);
  transition: all 0.2s ease-in-out;

  .navigationBrand {
    border-bottom: 1px solid var(--color-border);
    position: relative;
    height: 64px;
    display: flex;
    align-items: center;

    .logo {
      margin: 0 24px;

      img {
        width: 102px;
        height: 48px;
      }
    }

    .shortLogo {
      margin: 0 15px;
    }
  }

  .navContainer {
    list-style: none;
    padding: 22px 0;
    margin: 0;
    height: 100%;
    overflow-y: auto;
  }
}

.wideSidebar {
  max-width: 266px;
  width: 266px;

  @media only screen and (max-width: $screen-sm) {
    width: unset;
  }
}

.shortSidebar {
  max-width: 72px;
  box-shadow: unset;
}

.menuContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 114px);

  .appName {
    font-family: 'Segoe UI', sans-serif;
    color: #57534f9c;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
  }
}
