@import '../../../../../styles/breakpoints';

.button {
  border: none;
  outline: none;
  transition: all 0.2s ease-in-out;

  &:not(.disabled):hover {
    opacity: 0.8;
  }
}

.disabled {
  opacity: 0.6;
}
