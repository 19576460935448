.card {
  background: var(--surface-card);
  border: var(--card-border);
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}

.center {
  display: flex;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.w-80 {
  width: 80%;
}

.w-60 {
  width: 60%;
}

.h-80vh {
  height: calc(100vh - 90px - 78px - 50px - 30px);
}

.content {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
