/* Syncfusion imports */
@import '../../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-react-inputs/styles/material.css';

.e-view-content {
  overflow-y: auto;
  max-height: calc(100vh - 439px);
}

.ql-container.ql-snow {
  overflow-y: auto;
  max-height: calc(100vh - 505px);
  height: calc(100vh - 505px);
}

a.p-menuitem-link {
  color: black;
}

a.p-menuitem-link:hover {
  background-color: #f0f0f0;
}

li.p-highlight a.p-menuitem-link {
  border-color: black;
}

.e-view-header {
  display: none;
}

.e-views ol li {
  list-style-type: disc !important;
}

.e-views ul li {
  list-style-type: decimal !important;
}

.e-aiassistview .e-stop-response {
  border-color: black;
  color: #fff;
  background-color: grey;
}

.e-input-group.e-control-wrapper.e-multi-line-input.hide-scrollbar.e-auto-width::before,
.e-input-group.e-control-wrapper.e-multi-line-input.hide-scrollbar.e-auto-width::after {
  background-color: grey !important;
}

.e-input-group.e-control-wrapper.e-multi-line-input.hide-scrollbar.e-auto-width.e-input-focus::before,
.e-input-group.e-control-wrapper.e-multi-line-input.hide-scrollbar.e-auto-width.e-input-focus::after {
  background-color: grey !important;
}

.e-view-content {
  min-height: 150px;
}