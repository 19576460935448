@import '../../../styles/breakpoints';

.suggestions {
  background-color: var(--color-gray2);
  padding: 28px 24px 28px 24px;
  height: calc(100vh - 210px);
  overflow-y: hidden;

  .container {
    width: 80%;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @media only screen and (max-width: $screen-md) {
      width: 96%;
    }

    .pageHeader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;

      @media only screen and (max-width: $screen-xl) {
        display: block;
      }
    }
  }
}
