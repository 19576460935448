@import '../../../../../styles/breakpoints';

.inputBlock {
  .inputContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 40px;

    @media only screen and (max-width: $screen-md) {
      gap: 20px;
    }

    .bookButton {
      cursor: pointer;
    }
  }

  .disclaimer {
    margin-top: 16px;
    max-width: calc(100% - 74px);

    @media only screen and (max-width: $screen-md) {
      max-width: calc(100% - 54px);
    }

    .text {
      font-size: 12px;
      line-height: 1.1;
      color: var(--color-text2);

      @media only screen and (max-width: $screen-md) {
        font-size: 11px;
        line-height: 0.9;
      }
    }
  }
}
