@import '../../../styles/breakpoints';

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;

  .numberContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', sans-serif;
    font-size: 16px;
    line-height: 2.25;
    font-weight: 400;
    letter-spacing: 0.32px;
    color: var(--color-header-background);
    cursor: pointer;

    .active {
      font-weight: 600;
      color: var(--color-active);
      cursor: default;

      &:hover {
        color: var(--color-background-minor);
      }

      &:after {
        content: '';
        position: absolute;
        height: 2px;
        width: 150%;
        background: var(--color-header-background);
        bottom: 0;
        left: -25%;
      }
    }

    div {
      &:hover {
        color: var(--color-active);
      }

      &:first-child {
        margin-left: 20px;
      }

      margin-right: 20px;
    }

    .dots {
      pointer-events: none;
    }
  }
}
