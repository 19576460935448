@import '../../../../../styles/breakpoints';

.message {
  .avatarBlock {
    display: flex;
    align-items: flex-start;
    gap: 5px;

    .chatAvatar {
      width: 50px;
      height: 40px;
    }

    .userAvatar {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }

    .title {
      font-family: 'Segoe UI', sans-serif;
      font-size: 16px;
      line-height: 2.25;
      font-weight: 600;
      letter-spacing: 0.32px;
      color: var(--main-text-color);
    }
  }

  .messageContent {
    margin-left: 52px;
    max-width: calc(100% - 50px);
    padding: 20px 74px 20px 0;

    @media only screen and (max-width: $screen-md) {
      padding: 20px 54px 20px 0;
    }

    .messageText {
      color: var(--color-header-background);
      font-family: 'Segoe UI', sans-serif;
      font-size: 16px;
      line-height: 1.25;
      font-weight: 500;
      padding-bottom: 10px;
    }

    .chatMessage {
      // white-space: pre-wrap;
      word-wrap: break-word;
    }

    .dateBlock {
      .date {
        color: var(--color-gray);
        font-family: 'Segoe UI', sans-serif;
        font-size: 14px;
        line-height: 0.8;
        font-weight: 400;
        padding: 10px 0;
      }
    }
  }

  .userMessage {
    .messageText {
      color: var(--color-gray);
    }
  }
}
