.button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 6px;
  background-color: var(--color-background);
  cursor: pointer;
  outline: none;
  border: none;

  .title {
    font-family: 'Segoe UI', sans-serif;
    font-size: 16px;
    line-height: 2.25;
    font-weight: 600;
    letter-spacing: 0.32px;
  }
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

//button's variants
.primary {
  border: 1px solid var(--color-blue);

  .title {
    color: var(--color-blue);
  }
}

.blue {
  background-color: var(--color-blue);

  .title {
    color: var(--color-background);
  }
}

.grayText {
  .title {
    color: var(--color-gray);
  }
}

//button's width variants
.ai {
  width: 106px;
}

.apply {
  width: 64px;
}

.explore {
  width: 10rem;
}
