.btnWrapper {
  height: 40px;
  :hover {
    background-color: rgb(205, 219, 233) !important;
  }
}

.buttonStyles {
  height: 100%;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border: 0;
  border-radius: 0;
  background-color: inherit;
  text-decoration: none;
  padding: 0 0.5rem !important;
}
