.categories {
  font-family: 'Segoe UI', sans-serif;
  font-size: 16px;
  line-height: 2.25;
  font-weight: 600;
  letter-spacing: 0.32px;
  color: var(--main-text-color);
  display: flex;
  align-items: center;

  .arrow {
    transform: rotate(90deg);
  }
}
