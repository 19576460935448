@import 'src/app/common/styles/breakpoints';

.modalContainer {
  position: relative;
  margin: 0 auto;
  width: calc(100% - 170px);
  min-height: 100%;
  border-radius: 12px;
  background-color: var(--color-background);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.12);

  @media only screen and (max-width: $screen-sm) {
    width: calc(100% - 60px);
  }

  .header {
    padding: 20px 24px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-gray-border);

    .title {
      font-family: 'Segoe UI', sans-serif;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      color: var(--color-text2);
      overflow: hidden;
      text-overflow: ellipsis;
      -ms-text-overflow: ellipsis;
      -o-text-overflow: ellipsis;
      display: -webkit-box;
      word-wrap: break-word;
      -webkit-box-orient: vertical;
      -ms-line-clamp: 1;
      -webkit-line-clamp: 1;
      line-clamp: 1;
    }

    .search {
      display: flex;
      justify-content: space-between;
      gap: 2rem;
    }

    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .contentBlock {
    display: flex;
    height: 100vh;

    .analysisCategories {
      border-right: 1px solid var(--color-gray-border);
      padding-right: 6px;
      flex-basis: 210px;
      flex-shrink: 0;
      height: 88dvh;
      overflow: auto;
      margin-bottom: 2rem;

      @media only screen and (max-width: $screen-sm) {
        flex-shrink: unset;
      }

      .analysisCategoriesItem {
        padding: 10px 16px;

        .analysisCategoriesItemTitle {
          color: var(--color-gray-text);
          font-family: 'Segoe UI', sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: 1.43;
          text-transform: uppercase;
          cursor: pointer;
        }

        &.active {
          border-left: 3px solid var(--color-blue);

          .analysisCategoriesItemTitle {
            color: var(--color-blue);
          }
        }
      }
    }

    .services {
      overflow: auto;
      height: calc(100vh - 62px);
      width: 100%;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }
}
