@import '../../../../../styles/breakpoints';

.bookButton {
  cursor: pointer;
}

.closeButton {
  position: absolute;
  right: 7px;
  top: 7px;
  background: white;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0px 0px 4px 1px #a6a6a6;
  z-index: 1;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding: 20px;
  right: 50px;
  height: 60vh;
  width: 400px;
  overflow: hidden;
  bottom: 170px;
  border-radius: 10px;
  border: 1px solid var(--color-gray);
  background-color: var(--color-background);

  &.visible {
    display: block;
  }

  .searchInput {
    height: 40px;
    background-color: var(--color-background);
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-border);
    margin-bottom: 24px;

    .inputField {
      position: relative;
      flex-grow: 3;

      input {
        font-family: 'Segoe UI', sans-serif;
        font-size: 16px;
        line-height: 1.5;
        color: var(--color-text2);
        width: 100%;
        border: none;
        outline: none;
        background-color: var(--color-background);
        font-weight: 400;
      }

      ::placeholder {
        font-family: 'Segoe UI', sans-serif;
        font-size: 16px;
        line-height: 1.5;
        color: var(--color-light-gray);
        opacity: 1;
      }
    }
  }

  .suggestionList {
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: calc(100% - 82px);
    overflow: auto;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .suggestionItem {
      padding: 13px 24px;
      border-radius: 5px;
      cursor: pointer;

      &.borderBottom {
        border-bottom: 1px solid var(--color-border);
      }

      &:hover {
        background-color: var(--color-border);
      }

      .suggestionText {
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Segoe UI', sans-serif;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        display: -webkit-box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
        color: var(--color-text);
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        -ms-line-clamp: 2;
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    }
  }

  .noSuggestions {
    p {
      font-family: 'Segoe UI', sans-serif;
      font-size: 16px;
      line-height: 1.5;
      color: var(--color-text2);
    }
  }
}
