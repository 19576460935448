.customFooter {
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  width: max(70%, 425px);
  margin: auto;
}

.loader {
  height: 52.5px;
  width: calc(100% - 73px);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(240, 240, 240);
  border-radius: 5px;
  margin-bottom: 6.5px;
  left: 0;
}
