.arrow {
  display: inline-block;
  transition: all 0.2s ease-in-out;
  transform-origin: center;
  transform: rotate(180deg);
}

.open {
  transform: rotate(0);
  position: relative;
  top: -4px;
}
