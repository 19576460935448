.submenuItem {
  padding: 10px 0 10px 16px;
  opacity: 1;

  .submenuLink {
    text-decoration: none;
    font-family: 'Segoe UI', sans-serif;
    color: var(--color-text);
    font-size: 14px;
    font-weight: 400;
    line-height: 1.2;
  }
}

.activeSubmenuItem {
  border-left: 3px solid var(--color-blue);
  color: var(--color-blue);

  .submenuLink {
    color: var(--color-blue);
  }
}
