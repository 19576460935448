.item {
  overflow: hidden;

  .title,
  .submenuLink {
    font-family: 'Segoe UI', sans-serif;
    color: var(--color-text);
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }

  .itemHeader {
    display: flex;
    gap: 16px;
    cursor: pointer;
    padding: 12px 24px;

    .icon {
      width: 24px;
      height: 24px;
    }

    .titleGroup {
      display: flex;
      gap: 16px;
      justify-content: space-between;
      flex-basis: 100%;

      .title {
        white-space: nowrap;
      }
    }

    .shortTitleGroup {
      opacity: 0;
      overflow: hidden;
    }
  }

  .activeItemHeader {
    background-color: var(--color-blue-light);
  }

  .submenu {
    list-style: none;
    padding-left: 0;
    border-left: 1px solid var(--color-border);
    margin-left: 36px;
    overflow: hidden;

    .submenuItem {
      padding: 10px 0 10px 16px;
      opacity: 1;

      .submenuLink {
        text-decoration: none;
      }
    }

    .activeSubmenuItem {
      border-left: 3px solid var(--color-blue);

      .submenuLink {
        color: var(--color-blue);
      }
    }
  }

  .shortSubmenuItem {
    display: none;
  }

  .collapse {
    max-height: 0;
  }

  .active {
    max-height: 100%;
  }
}
