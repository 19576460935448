@import '../../../styles/breakpoints';

.suggestion {
  background-color: var(--color-gray2);
  padding: 28px 24px 28px 24px;
  height: calc(100vh - 210px);
  overflow-y: hidden;

  .container {
    width: 40%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-background);
    border-radius: 10px;
    border: 1px solid var(--color-active);
    padding: 20px;

    @media only screen and (max-width: $screen-lg) {
      width: 60%;
    }

    @media only screen and (max-width: $screen-sm) {
      width: 80%;
    }

    form {
      width: 100%;

      .submitButton {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40px;
        padding: 0 78px;
        gap: 8px;
        border-radius: 10px;
        background-color: var(--color-blue);
        cursor: pointer;
        outline: none;
        border: none;
        font-family: 'Segoe UI', sans-serif;
        font-size: 16px;
        line-height: 2.25;
        font-weight: 600;
        letter-spacing: 0.32px;
        color: var(--color-background);
        margin: 0 auto;

        @media only screen and (max-width: $screen-sm) {
          padding: 0 28px;
        }
      }
    }
  }
}
