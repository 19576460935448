@import 'src/app/common/styles/breakpoints';

.titleInput {
}

.incidentBlock {
  background-color: var(--color-background);
  width: 100%;
  min-width: 260px;
  border-radius: 16px;
  border: 1px solid var(--color-gray-border);
  overflow: auto;
  padding: 24px 24px 0 24px;

  @media only screen and (max-width: $screen-lg) {
    height: calc(100vh - 300px);
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .incidentBlockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    @media only screen and (max-width: $screen-lg) {
      flex-direction: column;
      align-items: flex-start;
    }

    .titleBlock {
      display: flex;
      align-items: center;
      gap: 16px;
      flex-grow: 1;

      @media only screen and (max-width: $screen-lg) {
        flex-direction: column;
        align-items: flex-start;
      }

      .avatar {
        width: 52px;
        height: 52px;
      }
    }
  }
}

.incidentBlockInnerContainer {
  // max-width: 1024px;
  margin: auto;
}

.contextButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}

.contentBlock {
  .content {
    font-family: 'Segoe UI', sans-serif;
    color: var(--color-text2);
    font-weight: 400;
    line-height: 1.5;
    padding-bottom: 16px;
    white-space: pre-line;
    font-size: 1.1rem;
    min-height: 7rem;
    // height: 20vh;
    // min-height: 20vh;
    width: 100%; /* Set a fixed width or max-width */
    overflow-x: auto;
  }

  .editArea {
    width: 100%;
    height: 800px;
  }
}

.editor {
  h1:hover,
  h2:hover,
  h3:hover,
  p:hover,
  ul:hover,
  ol:hover {
    outline: 2px solid #4eafed5e;
    border-radius: 5px;
    transition: outline 0.2s;
  }

  h1,
  h2,
  h3,
  p,
  ul,
  ol {
    outline: 2px solid transparent;
    border-radius: 5px;
    transition: outline 0.2s;
  }
}

.busyCog {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.htmlViewer {
  max-height: calc(100vh - 410px);
  border: 1px solid #ccc;
  border-radius: 0.475rem;
  padding: 12px 10px;
  overflow-y: auto;
  box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.0705882353);
}

.spinnerContainer {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.topRightButton {
  position: absolute;
  right: 55px;
  transform: scaleX(-1) rotate(270deg);
  z-index: 1;
  background: white;
  border-radius: 50%;
}
