@import 'src/app/common/styles/breakpoints';

.searchInputBlock {
  max-width: 306px;

  @media only screen and (max-width: $screen-md) {
    min-width: 230px;
  }

  .searchInput {
    height: 40px;
    background-color: var(--color-background);
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid var(--color-gray-border);
    max-width: 272px;
    margin-bottom: 24px;

    .inputField {
      position: relative;
      flex-grow: 3;

      input {
        font-family: 'Segoe UI', sans-serif;
        font-size: 16px;
        line-height: 1.5;
        color: var(--color-text2);
        width: 100%;
        border: none;
        outline: none;
        background-color: var(--color-background);
        font-weight: 400;
      }

      ::placeholder {
        font-family: 'Segoe UI', sans-serif;
        font-size: 16px;
        line-height: 1.5;
        color: var(--color-light-gray);
        opacity: 1;
      }
    }
  }

  .incidentList {
    list-style: none;
    padding: 0;
    margin: 0;
    height: calc(100vh - 220px);
    overflow: auto;

    @media only screen and (max-width: $screen-md) {
      height: calc(100vh - 300px);
    }

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .incident {
      padding: 13px 24px;
      height: 96px;
      border-bottom: 1px solid var(--color-border);
      cursor: pointer;

      &.active {
        background-color: var(--color-background);
        border-radius: 8px;
        border: 1px solid var(--color-gray-border);
      }

      &.prevActiveItem {
        border-bottom: unset;
      }

      .titleContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .title,
      .description {
        overflow: hidden;
        text-overflow: ellipsis;
        font-family: 'Segoe UI', sans-serif;
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        display: -webkit-box;
        word-wrap: break-word;
        -webkit-box-orient: vertical;
      }
      .title {
        color: var(--color-text2);
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
        -ms-line-clamp: 1;
        -webkit-line-clamp: 1;
        line-clamp: 1;
      }

      .description {
        color: var(--color-gray-text);
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        -ms-line-clamp: 2;
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    }
  }
}
