.loader {
  border: 2px solid rgba(73, 98, 168, 0.3);
  border-left-color: var(--color-active);
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
