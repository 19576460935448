@import 'src/app/common/styles/breakpoints';

.container {
  background-color: var(--color-gray2);
  margin-left: 72px;
  padding: 0 24px 0 24px;
  max-height: calc(100vh - 207px);

  @media only screen and (max-width: $screen-md) {
    overflow-x: auto;
  }

  .pageHeader {
    display: flex;
    justify-content: space-between;

    @media only screen and (max-width: $screen-xl) {
      display: block;
    }

    .dropdowns {
      display: flex;
      gap: 24px;

      @media only screen and (max-width: $screen-lg) {
        flex-direction: column;
      }
    }
  }

  .content {
    padding-top: 20px;
    display: flex;
    gap: 24px;
  }
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  gap: 0.5rem;
  width: 100%;
}

.searchInputBlockList {
  max-height: calc(100vh - 300px);
}
