@import '../../../styles/breakpoints';

.searchInput {
  height: 40px;
  background-color: var(--color-background);
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 6px;
  border: 1px solid var(--color-gray-border);
  max-width: 400px;
  flex-basis: 400px;

  @media only screen and (max-width: $screen-xl) {
    margin-top: 24px;
  }

  .searchIcon {
    color: var(--color-light-gray);
    flex-shrink: 0;
    flex-basis: 24px;
  }

  .inputField {
    position: relative;
    width: 100%;
    flex-grow: 3;

    input {
      font-family: 'Segoe UI', sans-serif;
      font-size: 16px;
      line-height: 1.5;
      color: var(--color-text2);
      width: 100%;
      border: none;
      outline: none;
      background-color: var(--color-background);
      font-weight: 400;
    }

    ::placeholder {
      font-family: 'Segoe UI', sans-serif;
      font-size: 16px;
      line-height: 1.5;
      color: var(--color-light-gray);
      opacity: 1;
    }
  }
}
