@import 'src/app/common/styles/breakpoints';

.incidentBlock {
  height: calc(100vh - 213px);
  background-color: var(--color-background);
  width: 100%;
  min-width: 260px;
  border-radius: 16px;
  border: 1px solid var(--color-gray-border);
  overflow: auto;
  padding: 24px 24px 0 24px;

  @media only screen and (max-width: $screen-lg) {
    height: calc(100vh - 300px);
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .incidentBlockHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;

    @media only screen and (max-width: $screen-lg) {
      flex-direction: column;
      align-items: flex-start;
    }

    .titleBlock {
      display: flex;
      align-items: center;
      gap: 16px;

      @media only screen and (max-width: $screen-lg) {
        flex-direction: column;
        align-items: flex-start;
      }

      .avatar {
        width: 52px;
        height: 52px;
      }
    }
  }
}
.contextButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-top: 16px;
}

.contentBlock {
  padding-top: 24px;

  .content {
    font-family: 'Segoe UI', sans-serif;
    color: var(--color-text2);
    font-weight: 400;
    line-height: 1.5;
    padding-bottom: 16px;
    white-space: pre-line;
    font-size: 1.1rem;
    min-height: 7rem;
    // height: 20vh;
    // min-height: 20vh;
    width: 100%; /* Set a fixed width or max-width */
    overflow-x: auto;
  }
}
