@import '../../../styles/breakpoints';

.suggestion {
  padding: 13px 24px;
  border-bottom: 1px solid var(--color-border);
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .text {
    font-family: 'Segoe UI', sans-serif;
    color: var(--color-gray-text);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .icons {
    display: flex;
    gap: 20px;
    margin-top: 10px;

    a,
    .button {
      align-self: flex-start;
    }

    .loader {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .icon {
      flex-shrink: 0;
      cursor: pointer;
      color: var(--color-gray);

      &:hover {
        color: var(--color-active);
      }
    }
  }
}
