.appSubtitle {
  font-family: 'Segoe UI', sans-serif;
  font-size: 20px;
  line-height: 1.2;
  font-weight: 600;
  color: var(--color-text2);

  &::first-letter {
    text-transform: capitalize;
  }
}
