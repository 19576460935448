@import '../../../../../styles/breakpoints';

.autoComplete {
  width: 100%;
  height: 100%;
  border: transparent;
}

.searchInputBlock {
  width: 100%;

  .searchInput {
    padding: 10px 16px;
    display: flex;
    align-items: flex-end;
    gap: 8px;
    border-radius: 6px;
    background-color: var(--color-light-gray3);

    .inputField {
      position: relative;
      flex-grow: 3;

      .p-autocomplete.p-component {
        width: 100%;
      }

      textarea,
      input {
        font-family: 'Segoe UI', sans-serif;
        font-size: 16px;
        line-height: 1.5;
        color: var(--color-text2);
        width: 100%;
        border: none;
        outline: none;
        background-color: var(--color-light-gray3);
        font-weight: 400;
        resize: none;
        height: 30px;
        overflow-y: hidden;
        box-sizing: border-box;
        appearance: none;
        box-shadow: none;
      }

      ::placeholder {
        font-family: 'Segoe UI', sans-serif;
        font-size: 16px;
        line-height: 1.5;
        color: var(--color-light-gray);
        opacity: 1;
      }

      textarea::-webkit-resizer {
        display: none;
      }

      .suggestion {
        position: absolute;
        top: 1.2px;
        left: 1px;
        width: 100%;
        font-size: 16px;
        line-height: 1.6;
        color: var(--color-text2);
        pointer-events: none;
        white-space: pre-wrap;
        word-break: break-word;

        .searchTerm {
          color: transparent;
        }

        span {
          color: var(--color-gray);
        }
      }
    }
  }

  .arrow {
    color: var(--color-text-minor);
  }

  .sendButton {
    background-color: var(--color-light-gray);
    border-radius: 6px;
    padding: 4px;
    cursor: pointer;
  }
}
