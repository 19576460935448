@import '../../../styles/breakpoints';

.selectField {
  padding: 10px 0;

  .label {
    font-family: 'Segoe UI', sans-serif;
    font-size: 15px;
    line-height: 1.4;
    color: var(--color-gray-text);
    font-weight: 400;
    padding-bottom: 10px;
  }

  .select {
    font-family: 'Segoe UI', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: var(--color-text2);
    width: 100%;
    border: none;
    outline: none;
    font-weight: 400;
    appearance: none;
    border-radius: 10px;
    box-sizing: border-box;
  }
}
