@import '../../../styles/breakpoints';

.list {
  list-style: none;
  padding: 0;
  margin: 0;
  height: calc(100vh - 220px);
  overflow: auto;

  @media only screen and (max-width: $screen-md) {
    height: calc(100vh - 300px);
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
