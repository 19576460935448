@import '../../../styles/breakpoints';

.textField {
  position: relative;
  padding: 20px 0;

  .label {
    font-family: 'Segoe UI', sans-serif;
    font-size: 15px;
    line-height: 1.4;
    color: var(--color-gray-text);
    font-weight: 400;
  }

  textarea {
    margin-top: 10px;
    font-family: 'Segoe UI', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: var(--color-text2);
    width: 100%;
    border: none;
    outline: none;
    background-color: var(--color-light-gray3);
    font-weight: 400;
    resize: none;
    height: 200px;
    overflow-y: hidden;
    box-sizing: border-box;
    appearance: none;
    padding: 10px;
    border-radius: 10px;
  }

  ::placeholder {
    font-family: 'Segoe UI', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: var(--color-light-gray);
    opacity: 1;
  }

  textarea::-webkit-resizer {
    display: none;
  }
}
