//
// Header
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: get($header-config, desktop, default, height);
    transition: left get($aside-config, transition-speed) ease;
    background-color: var(--#{$prefix}header-desktop-fixed-bg-color);
    color: #fff;
    padding: 10px 30px 10px 10px;

    // Fixed header & sticky header modes
    .header-fixed[data-kt-sticky-header='on'] & {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: get($header-config, desktop, fixed, z-index);
      background-color: var(--#{$prefix}header-desktop-fixed-bg-color);
      box-shadow: var(--#{$prefix}header-desktop-fixed-box-shadow);
      height: get($header-config, desktop, fixed, height);
    }

    // Aside fixed, aside secondary enabled and header sticky modes
    .aside-fixed.aside-secondary-enabled[data-kt-sticky-header='on'] & {
      left: get($aside-config, width, desktop);
      transition: left get($aside-config, transition-speed) ease;
    }

    // Aside fixed, aside secondary enabled and header sticky modes
    .aside-fixed.aside-secondary-disabled[data-kt-sticky-header='on'] & {
      left: get($aside-config, primary-width, desktop);
      transition: left get($aside-config, transition-speed) ease;
    }

    // Aside fixed, aside secondary enabled,header sticky & aside minimize modes
    .aside-fixed.aside-secondary-enabled[data-kt-sticky-header='on'][data-kt-aside-minimize='on']
      & {
      left: get($aside-config, primary-width, desktop);
      transition: left get($aside-config, transition-speed) ease;
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    height: get($header-config, tablet-and-mobile, default, height);

    // Fixed header and header sticky modes
    .header-tablet-and-mobile-fixed[data-kt-sticky-header='on'] & {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: get($header-config, tablet-and-mobile, fixed, z-index);
      height: get($header-config, tablet-and-mobile, fixed, height);
      min-height: get($header-config, tablet-and-mobile, fixed, height);
      background-color: var(--#{$prefix}header-tablet-and-mobile-fixed-bg-color);
      box-shadow: var(--#{$prefix}header-tablet-and-mobile-fixed-box-shadow);
    }

    // Page title
    .page-title {
      display: none !important;
    }
  }
}
