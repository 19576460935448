.container {
  padding: 2px;
  border: 1.5px solid var(--color-header-background);
  border-radius: 6px;
  transform: rotate(90deg);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  &:hover {
    border: 1.5px solid var(--color-active);
    background-color: rgb(73, 98, 168, 0.3);
  }
  &.next {
    transform: rotate(-90deg);
  }

  .arrow {
    color: var(--color-header-background);

    &:hover {
      color: var(--color-active);
    }
  }
}

.disabled {
  pointer-events: none;
}
