.arrowButton {
  position: absolute;
  bottom: -24px;
  right: -26px;
  z-index: 10;
  transform: rotate(180deg);

  img {
    width: 52px;
    height: 52px;
    transform-origin: center;
  }
}

.open {
  transform: rotate(0);
  bottom: -31px;
}
